import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Form } from './styles'
import FormInput from '../FormInput'
import FormButton from '../FormButton'
import { resetPassword } from '../../services/authentication'
import Container from '../Container'

const ResetPassword = () => {
  const [password, setPassword] = useState('')
  const [confirmationPassword, setConfirmationPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [genericFormError, setGenericFormError] = useState('')
  const [passwordError, setPasswordError] = useState(null)
  const { token } = useParams()
  const navigate = useNavigate()

  const handleFormSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    setPasswordError(null)
    setGenericFormError('')

    if (password !== confirmationPassword) {
      setIsLoading(false)
      setPasswordError('Passwords do not match.')
      return
    }

    resetPassword(token, password)
      .then(() => {
        toast.success('Password updated!')
        navigate('/')
      })
      .catch(() =>
        setGenericFormError('Error while trying to reset password. Please try again later'),
      )

    setIsLoading(false)
  }

  return (
    <Container>
      <Form onSubmit={handleFormSubmit}>
        <h1>Reset Password</h1>
        {genericFormError && <p style={{ color: 'red' }}>{genericFormError}</p>}

        <FormInput
          id='password'
          label='Password'
          type='password'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder='Enter your password'
        />

        <FormInput
          id='confirmationPassword'
          label='Confirm Password'
          type='password'
          value={confirmationPassword}
          onChange={(e) => setConfirmationPassword(e.target.value)}
          placeholder='Confirm your password'
          errorMessage={passwordError}
        />
        <FormButton label='Reset Password' loadingLabel='Resetting...' isLoading={isLoading} />
      </Form>
    </Container>
  )
}

export default ResetPassword
