import React from 'react'
import PropTypes from 'prop-types'
import { Button } from './styles'

const FormButton = ({ label, loadingLabel, isLoading }) => (
  <Button type='submit' disabled={isLoading}>
    {isLoading ? loadingLabel : label}
  </Button>
)

FormButton.propTypes = {
  label: PropTypes.string.isRequired,
  loadingLabel: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
}

export default FormButton
