import styled, { css } from 'styled-components'

export const Container = styled.div`
  margin-bottom: 20px;
`

export const Label = styled.label`
  font-size: 18px;
  margin-bottom: 10px;
  display: block;
  ${(props) => css`
    color: ${props.$error ? 'red' : '#000'};
  `}
`

export const Input = styled.input`
  font-size: 16px;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 6px;
  transition: border-color 0.2s ease;
  width: calc(100% - 24px);
  ${(props) => css`
    border-color: ${props.$error ? 'red' : '#ccc'};
    &:focus {
      outline: none;
      border-color: ${props.$error ? 'red' : '#007bff'};
    }
  `}
`

export const ErrorMessage = styled.span`
  color: red;
`
