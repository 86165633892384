import React from 'react'
import PropTypes from 'prop-types'

const History = ({ items }) => (
  <div>
    <h3>History:</h3>
    <ul>
      {items.map((item) => (
        <li key={item.expression}>
          {item.expression} = {item.result}
        </li>
      ))}
    </ul>
  </div>
)

History.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      expression: PropTypes.string,
      result: PropTypes.string,
    }),
  ).isRequired,
}

export default History
