import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const SeparatorWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
`

const SeparatorLine = styled.div`
  flex: 1;
  height: 1px;
  background-color: #ccc;
`

const SeparatorText = styled.span`
  margin: 0 10px;
  color: #888;
  font-weight: bold;
  font-size: 16px;
`

const Separator = ({ text }) => (
  <SeparatorWrapper>
    <SeparatorLine />
    <SeparatorText>{text}</SeparatorText>
    <SeparatorLine />
  </SeparatorWrapper>
)

Separator.propTypes = {
  text: PropTypes.string.isRequired,
}

export default Separator
