import React from 'react'
import PropTypes from 'prop-types'

import { Button } from './styles'

const ExpressionButton = ({ value, onClick, expressionDisplay }) => {
  const display = expressionDisplay || value
  return (
    <Button type='button' onClick={() => onClick(value)}>
      {display}
    </Button>
  )
}

ExpressionButton.propTypes = {
  value: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  expressionDisplay: PropTypes.string,
}

ExpressionButton.defaultProps = {
  expressionDisplay: null,
}

export default ExpressionButton
