import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { FaUser } from 'react-icons/fa'
import {
  NavBarContainer,
  Logo,
  NavLinks,
  NavLink,
  DropdownMenu,
  DropdownItem,
  DropdownButton,
} from './styles'
import { getProfile, logout, hasAccessToken } from '../../services/authentication'

const NavBar = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(false)
  const [user, setUser] = useState(null)
  const isAuthenticated = user !== null

  const navigate = useNavigate()

  const toggleDropdown = () => {
    setDropdownOpen((prevOpen) => !prevOpen)
  }

  const handleLogout = () => {
    logout()
      .then(() => {
        navigate('/')
      })
      .catch(() => {
        toast.error('Error when trying to logout!')
      })
  }

  useEffect(() => {
    if (hasAccessToken()) {
      getProfile()
        .then((userData) => {
          setUser(userData)
        })
        .catch(() => setUser(null))
    }
  }, [])

  return (
    <NavBarContainer>
      <Logo>Calculator</Logo>
      <NavLinks>
        {!isAuthenticated && (
          <li>
            <NavLink onClick={() => navigate('/')}>Login</NavLink>
          </li>
        )}
        {isAuthenticated && (
          <li>
            <DropdownButton onClick={toggleDropdown}>
              <FaUser /> {user.name}
            </DropdownButton>
            <DropdownMenu open={isDropdownOpen}>
              <DropdownItem onClick={handleLogout}>Logout</DropdownItem>
            </DropdownMenu>
          </li>
        )}
      </NavLinks>
    </NavBarContainer>
  )
}

export default NavBar
