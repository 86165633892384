import styled from 'styled-components'

export const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
`

export const OptionButton = styled.button`
  font-size: 18px;
  background-color: transparent;
  border: none;
  color: #007bff;
  cursor: pointer;
  transition: color 0.2s ease;
  margin-bottom: 16px;
  padding: 8px;
  border: 1px solid #007bff;
  border-radius: 20px;

  &:hover {
    color: #0056b3;
  }
`

export const ForgotPasswordButton = styled.a`
  font-size: 12px;
  background-color: transparent;
  border: none;
  color: #007bff;
  cursor: pointer;
  transition: color 0.2s ease;
  margin-top: 8px;

  &:hover {
    color: #0056b3;
  }
`
