/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'

export const Button = styled.button`
  font-size: 20px;
  padding: 14px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  background-color: #007bff;
  color: #ffffff;
  font-weight: bold;

  &:hover {
    background-color: #0056b3;
  }
`
