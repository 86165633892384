import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Form } from './styles'
import FormInput from '../FormInput'
import FormButton from '../FormButton'
import { forgotPassword } from '../../services/authentication'
import Container from '../Container'

const ForgotPassword = () => {
  const [email, setEmail] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [genericFormError, setGenericFormError] = useState('')
  const [emailError, setEmailError] = useState(null)
  const navigate = useNavigate()

  const handleFormSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    setEmailError(null)
    setGenericFormError('')

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (!emailRegex.test(email)) {
      setEmailError('Invalid email format!')
      setIsLoading(false)
      return
    }

    forgotPassword(email)
      .then(() => {
        toast.success('Please check your email for instructions about resetting your password!')
        navigate('/')
      })
      .catch(() =>
        setGenericFormError('Error while trying to reset password. Please try again later'),
      )

    setIsLoading(false)
  }

  return (
    <Container>
      <Form onSubmit={handleFormSubmit}>
        <h1>Forgot Password</h1>
        {genericFormError && <p style={{ color: 'red' }}>{genericFormError}</p>}

        <FormInput
          id='email'
          label='Email'
          type='text'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder='Enter your email'
          errorMessage={emailError}
        />

        <FormButton label='Submit' loadingLabel='Submitting in...' isLoading={isLoading} />
      </Form>
    </Container>
  )
}

export default ForgotPassword
