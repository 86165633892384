import React, { useState } from 'react'
import * as math from 'mathjs'
import { CalculatorContainer, Display, ButtonGrid } from './styles'

import ExpressionButton from './ExpressionButton'
import OperationButton from './OperationButton'
import MemoryOperators from './MemoryOperators'
import History from './History'

const Calculator = () => {
  const [displayValue, setDisplayValue] = useState('0')
  const [isShowingResult, setIsShowingResult] = useState(false)
  const [history, setHistory] = useState([])

  const isNum = (value) => /\d/.test(value)

  const isStartingNewExpression = (value) => {
    if (displayValue === '0') {
      return true
    }

    return isShowingResult === true && (isNum(value) || ['(', ')'].includes(value))
  }

  const handleBuildExpression = (value) => {
    if (isStartingNewExpression(value)) {
      setDisplayValue(value)
    } else {
      setDisplayValue((prevDisplay) => prevDisplay + value)
    }
    setIsShowingResult(false)
  }

  const handleDecimal = () => {
    setDisplayValue((prevDisplay) => `${prevDisplay}.`)
  }

  const handleClear = () => {
    setDisplayValue('0')
  }

  const handleCalculate = () => {
    try {
      const result = math.evaluate(displayValue)
      setDisplayValue(result.toString())
      setIsShowingResult(true)

      setHistory((prevHistory) => [
        ...prevHistory,
        { expression: displayValue, result: result.toString() },
      ])
    } catch (error) {
      setDisplayValue('Error')
    }
  }

  const handleSquareRoot = () => {
    try {
      const result = math.evaluate(`sqrt(${displayValue})`)
      setDisplayValue(result.toString())

      setHistory((prevHistory) => [
        ...prevHistory,
        { expression: `√${displayValue}`, result: result.toString() },
      ])
    } catch (error) {
      setDisplayValue('Error')
    }
  }

  const handleToggleSign = () => {
    setDisplayValue((prevDisplay) => {
      if (prevDisplay.startsWith('-')) {
        return prevDisplay.slice(1)
      }
      return `-${prevDisplay}`
    })
  }

  const handleBackspace = () => {
    if (isShowingResult) {
      return null
    }

    if (displayValue.length === 1) {
      return setDisplayValue('0')
    }

    return setDisplayValue((prevDisplay) => prevDisplay.slice(0, -1))
  }

  const clearButtonTheme = {
    color: '#fff',
    backgroundColor: '#f08080',
    hoverBackgroundColor: '#e75454',
    bold: true,
  }

  const equalButtonTheme = {
    color: '#fff',
    backgroundColor: '#4caf50',
    hoverBackgroundColor: '#45a049',
  }

  return (
    <CalculatorContainer>
      <Display>{displayValue}</Display>
      <ButtonGrid>
        <ExpressionButton value='%' onClick={handleBuildExpression} />
        <ExpressionButton value='(' onClick={handleBuildExpression} />
        <ExpressionButton value=')' onClick={handleBuildExpression} />
        <OperationButton data-testid='backspace-button' value='&#9003;' onClick={handleBackspace} />

        <ExpressionButton value='7' onClick={handleBuildExpression} />
        <ExpressionButton value='8' onClick={handleBuildExpression} />
        <ExpressionButton value='9' onClick={handleBuildExpression} />
        <ExpressionButton value='/' onClick={handleBuildExpression} />

        <ExpressionButton value='4' onClick={handleBuildExpression} />
        <ExpressionButton value='5' onClick={handleBuildExpression} />
        <ExpressionButton value='6' onClick={handleBuildExpression} />
        <ExpressionButton value='*' expressionDisplay='x' onClick={handleBuildExpression} />

        <ExpressionButton value='1' onClick={handleBuildExpression} />
        <ExpressionButton value='2' onClick={handleBuildExpression} />
        <ExpressionButton value='3' onClick={handleBuildExpression} />
        <ExpressionButton value='-' onClick={handleBuildExpression} />

        <OperationButton value='+/-' onClick={handleToggleSign} />
        <ExpressionButton value='0' onClick={handleBuildExpression} />
        <OperationButton value='.' onClick={handleDecimal} />
        <ExpressionButton value='+' onClick={handleBuildExpression} />

        <OperationButton value='√' onClick={handleSquareRoot} />
        <ExpressionButton value='^' onClick={handleBuildExpression} />
        <OperationButton value='C' theme={clearButtonTheme} onClick={handleClear} />
        <OperationButton
          type='button'
          theme={equalButtonTheme}
          value='='
          onClick={handleCalculate}
        />

        <MemoryOperators displayValue={displayValue} setDisplayValue={setDisplayValue} />
      </ButtonGrid>

      <History items={history} />
    </CalculatorContainer>
  )
}

export default Calculator
