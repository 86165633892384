import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Form } from './styles'
import FormInput from '../FormInput'
import FormButton from '../FormButton'
import { register } from '../../services/authentication'
import Container from '../Container'

const Register = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmationPassword, setConfirmationPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [registerError, setRegisterError] = useState('')
  const [emailError, setEmailError] = useState(null)
  const [passwordError, setPasswordError] = useState(null)
  const navigate = useNavigate()

  const handleRegister = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    setEmailError(null)
    setRegisterError('')

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    const emailValid = emailRegex.test(email)
    const passwordsMatchs = password === confirmationPassword
    if (!emailValid) {
      setEmailError('Invalid email format!')
    }

    if (!passwordsMatchs) {
      setPasswordError('Passwords do not match.')
    }

    if (emailValid && passwordsMatchs) {
      register(name, email, password)
        .then(() => {
          toast.success('Registration Complete!')
          navigate('/')
        })
        .catch(() => setRegisterError('Error while trying to register. Please try again later'))
    }

    setIsLoading(false)
  }

  return (
    <Container>
      <Form onSubmit={handleRegister}>
        <h1>Register</h1>
        {registerError && <p style={{ color: 'red' }}>{registerError}</p>}

        <FormInput
          id='name'
          label='Name'
          type='text'
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder='Enter your name'
        />

        <FormInput
          id='email'
          label='Email'
          type='text'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder='Enter your email'
          errorMessage={emailError}
        />

        <FormInput
          id='password'
          label='Password'
          type='password'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder='Enter your password'
        />

        <FormInput
          id='confirmationPassword'
          label='Confirm Password'
          type='password'
          value={confirmationPassword}
          onChange={(e) => setConfirmationPassword(e.target.value)}
          placeholder='Confirm your password'
          errorMessage={passwordError}
        />
        <FormButton label='Register' loadingLabel='Registering in...' isLoading={isLoading} />
      </Form>
    </Container>
  )
}

export default Register
