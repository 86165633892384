import styled, { css } from 'styled-components'

export const CalculatorContainer = styled.div`
  max-width: 400px;
  border: 1px solid #ccc;
  border-radius: 20px;
  padding: 10px;
  margin: 20px auto;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  font-family: 'Montserrat', sans-serif;
`

export const Display = styled.div`
  font-size: 24px;
  background-color: #f3f3f3;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  margin-bottom: 10px;
  text-align: right;
`

export const ButtonGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 5px;
`

export const Button = styled.button`
  font-size: 20px;
  padding: 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;

  ${(props) => css`
    background-color: ${props.theme.backgroundColor};
    color: ${props.theme.color};
    font-weight: ${props.theme.bold ? 'bold' : 'normal'};
    &:hover {
      background-color: ${props.theme.hoverBackgroundColor};
    }
  `}
`

Button.defaultProps = {
  theme: {
    backgroundColor: '#f0f0f0',
    color: '#000000',
    hoverBackgroundColor: '#d0d0d0',
  },
}
