import React from 'react'
import Calculator from '../components/Calculator'
import NavBar from '../components/NavBar'

const CalculatorPage = () => (
  <>
    <NavBar />
    <Calculator />
  </>
)

export default CalculatorPage
