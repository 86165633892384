import React from 'react'
import PropTypes from 'prop-types'

import { Button } from './styles'

const OperationButton = ({ value, onClick, ...props }) => (
  <Button type='button' onClick={onClick} {...props}>
    {value}
  </Button>
)

OperationButton.propTypes = {
  value: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default OperationButton
