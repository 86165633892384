const AUTH_SVC_URL = process.env.REACT_APP_AUTH_SVC_URL

const storeAuthenticationData = (data) =>
  localStorage.setItem('authentication', JSON.stringify(data))

const removeAuthenticationData = () => localStorage.removeItem('authentication')

const getAccessToken = () => JSON.parse(localStorage.getItem('authentication')).access_token

export const hasAccessToken = () => localStorage.getItem('authentication') !== null

export const login = (email, password) =>
  fetch(`${AUTH_SVC_URL}/auth/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
      password,
    }),
  }).then(async (response) => {
    if (!response.ok) {
      return Promise.reject(Error('Invalid credentials'))
    }
    storeAuthenticationData(await response.json())
    return response
  })

export const register = (name, email, password) =>
  fetch(`${AUTH_SVC_URL}/users`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      name,
      email,
      password,
    }),
  }).then((response) => {
    if (!response.ok) {
      return Promise.reject(Error('Error while trying to register'))
    }
    return response
  })

export const forgotPassword = (email) =>
  fetch(`${AUTH_SVC_URL}/auth/reset-password`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
    }),
  }).then((response) => {
    if (!response.ok) {
      return Promise.reject(Error('Error while trying ask to reset password'))
    }
    return response
  })

export const resetPassword = (token, newPassword) =>
  fetch(`${AUTH_SVC_URL}/auth/reset-password/${token}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      new_password: newPassword,
    }),
  }).then((response) => {
    if (!response.ok) {
      return Promise.reject(Error('Error while trying to reset password'))
    }
    return response
  })

export const getProfile = () =>
  fetch(`${AUTH_SVC_URL}/profile`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  })
    .then((response) => {
      if (!response.ok) {
        return Promise.reject(Error('Error while trying to get profile'))
      }
      return response.json()
    })
    .catch((error) => Promise.reject(Error(`Error ${error} while trying to get profile`)))

export const logout = () =>
  fetch(`${AUTH_SVC_URL}/auth/logout`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }).then(async (response) => {
    if (!response.ok) {
      return Promise.reject(Error('Invalid credentials'))
    }
    removeAuthenticationData()
    return response
  })
