import React from 'react'
import PropTypes from 'prop-types'
import { Container, Label, Input, ErrorMessage } from './styles'

const FormInput = ({ label, type, id, value, onChange, placeholder, errorMessage }) => (
  <Container>
    <Label $error={errorMessage !== null} htmlFor={id}>
      {label}
    </Label>
    <Input
      $error={errorMessage !== null}
      type={type}
      id={id}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
    />
    {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
  </Container>
)

FormInput.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
}

FormInput.defaultProps = {
  errorMessage: null,
}

export default FormInput
