import React, { useState } from 'react'
import PropTypes from 'prop-types'
import OperationButton from './OperationButton'

const MemoryOperators = ({ displayValue, setDisplayValue }) => {
  const [memory, setMemory] = useState(0)
  const isMemoryStored = memory !== 0

  const handleMemoryPlus = () => {
    const currentValue = parseFloat(displayValue)
    setMemory((prevMemory) => prevMemory + currentValue)
  }

  const handleMemoryMinus = () => {
    const currentValue = parseFloat(displayValue)
    setMemory((prevMemory) => prevMemory - currentValue)
  }

  const handleMemoryRecall = () => {
    setDisplayValue(memory.toString())
  }

  const handleMemoryClear = () => {
    setMemory(0)
  }

  const memoryRecallButtonTheme = {
    backgroundColor: isMemoryStored ? '#ffcc66' : null,
    bold: isMemoryStored,
  }

  return (
    <>
      <OperationButton value='M+' onClick={handleMemoryPlus} />
      <OperationButton value='M-' onClick={handleMemoryMinus} />
      <OperationButton
        type='button'
        theme={memoryRecallButtonTheme}
        onClick={handleMemoryRecall}
        value='MR'
      />
      <OperationButton value='MC' onClick={handleMemoryClear} />
    </>
  )
}

MemoryOperators.propTypes = {
  displayValue: PropTypes.string.isRequired,
  setDisplayValue: PropTypes.func.isRequired,
}

export default MemoryOperators
