import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { LoginForm, OptionButton, ForgotPasswordButton } from './styles'
import FormInput from '../FormInput'
import FormButton from '../FormButton'
import Separator from './Separator'
import { login } from '../../services/authentication'
import Container from '../Container'

const Login = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [loginError, setLoginError] = useState('')
  const [emailError, setEmailError] = useState(null)
  const navigate = useNavigate()

  const handleLogin = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    setEmailError(null)
    setLoginError('')

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (!emailRegex.test(email)) {
      setEmailError('Invalid email format!')
      setIsLoading(false)
      return
    }

    login(email, password)
      .then(() => {
        navigate('/calculator')
      })
      .catch(() => {
        setLoginError('Login failed. Please check your credentials and try again.')
      })

    setIsLoading(false)
  }

  return (
    <Container>
      <LoginForm onSubmit={handleLogin}>
        <h1>Login</h1>
        {loginError && <p style={{ color: 'red' }}>{loginError}</p>}

        <FormInput
          id='email'
          label='Email'
          type='text'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder='Enter your email'
          errorMessage={emailError}
        />

        <FormInput
          id='password'
          label='Password'
          type='password'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder='Enter your password'
        />

        <FormButton label='Login' loadingLabel='Logging in...' isLoading={isLoading} />
        <ForgotPasswordButton onClick={() => navigate('/forgot-password')}>
          Forgot Password?
        </ForgotPasswordButton>

        <Separator text='OR' />

        <OptionButton onClick={() => navigate('/register')}>Register</OptionButton>
        <OptionButton onClick={() => navigate('/calculator')}>Continue without login</OptionButton>
      </LoginForm>
    </Container>
  )
}

export default Login
