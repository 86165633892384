import styled from 'styled-components'

export const NavBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f0f0f0;
  height: 60px;
  padding: 0 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  font-family: 'Montserrat', sans-serif;
`

export const Logo = styled.div`
  font-size: 24px;
  font-weight: bold;
`

export const NavLinks = styled.ul`
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    margin-right: 20px;
    cursor: pointer;
  }
`

export const NavLink = styled.a`
  color: #000000;
  text-decoration: none;
  transition: color 0.2s ease;

  &:hover {
    color: #d0d0d0;
  }
`

export const DropdownMenu = styled.div`
  position: absolute;
  top: 55px;
  right: 20px;
  display: ${({ open }) => (open ? 'block' : 'none')};
  background-color: #f0f0f0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 1;
`

export const DropdownItem = styled.div`
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #d0d0d0;
  }
`

export const DropdownButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: #000000;
  transition: color 0.2s ease;

  &:hover {
    color: #d0d0d0;
  }
`
